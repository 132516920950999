export enum Permissions {
  BhbPracticeRead = "BH.BOOKING.PRACTICE.READ",
  BhbPracticeWrite = "BH.BOOKING.PRACTICE.WRITE",
  BhbPracticeManagementRead = "BHB.PRACTICEMANAGEMENT.READ",
  BhbPracticeManagementWrite = "BHB.PRACTICEMANAGEMENT.WRITE",
  BhBannerRead = "BH.BANNER.READ",
  BhBannerWrite = "BH.BANNER.WRITE",
  BhMobileAppRead = "BH.MOBILE-APP.READ",
  BhMobileAppWrite = "BH.MOBILE-APP.WRITE",
  BhMobileNotificationsRead = "BH.MOBILE-NOTIFICATIONS.READ",
  BhMobileNotificationsWrite = "BH.MOBILE-NOTIFICATIONS.WRITE",
  BhMobileSettingsRead = "BH.MOBILE-SETTINGS.READ",
  BhMobileSettingsWrite = "BH.MOBILE-SETTINGS.WRITE",
  BhUserDelete = "BH.USER.DELETE",
  BhUserRead = "BH.USER.READ",
  EnvSecurityRead = "ENV.SECURITY.READ",
  EnvSecurityWrite = "ENV.SECURITY.WRITE",
  OpsConsolePreRelease = "OPS.CONSOLE.PRERELEASE",
  OpsEventHubPoisonMessageRead = "OPS.EH-POISON-MESSAGE.READ",
  OpsEventHubPoisonMessageWrite = "OPS.EH-POISON-MESSAGE.WRITE",
  OpsEventHubPoisonMessageValueRead = "OPS.EH-POISON-MESSAGE-VALUE.READ",
  PltBpidUserRead = "PLT.BPID.USER.READ",
  PltBpidUserWrite = "PLT.BPID.USER.WRITE",
  PltBpidUserClear = "PLT.BPID.USER.CLEAR",
  PltCatalogOpsRead = "PLT.CATALOG-OPS.READ",
  PltCatalogOpsWrite = "PLT.CATALOG-OPS.WRITE",
  PltCatalogTenantCreate = "PLT.CATALOG-TENANT.CREATE",
  PltCatalogTenantActivate = "PLT.CATALOG-TENANT.ACTIVATE",
  PltCatalogTenantDeactivate = "PLT.CATALOG-TENANT.DEACTIVATE",
  PltCatalogTenantRead = "PLT.CATALOG-TENANT.READ",
  PltCatalogTenantWrite = "PLT.CATALOG-TENANT.WRITE",
  PltCatalogTenantProvision = "PLT.CATALOG-TENANT.PROVISION",
  PltCatalogUserRead = "PLT.CATALOG-USER.READ",
  PltCatalogUserWrite = "PLT.CATALOG-USER.WRITE",
  PltCatalogUserAuthenticationWrite = "PLT.CATALOG-USERAUTHENTICATION.WRITE",
  PltCommsWrite = "PLT.COMMS.WRITE",
  PltCommsCreditsRead = "PLT.COMMS-CREDITS.READ",
  PltCommsCreditsWrite = "PLT.COMMS-CREDITS.WRITE",
  PltCommsSmsAuditRead = "PLT.COMMS-SMSAUDIT.READ",
  PltCustomerRead = "PLT.CUSTOMER.READ",
  PltCustomerWrite = "PLT.CUSTOMER.WRITE",
  PltFeatureRead = "PLT.FEATURE.READ",
  PltFieldManagementRead = "PLT.FIELD-MANAGEMENT.READ",
  PltFieldManagementWrite = "PLT.FIELD-MANAGEMENT.WRITE",
  PltIntegAppRead = "PLT.INTEG-APP.READ",
  PltIntegAppWrite = "PLT.INTEG-APP.WRITE",
  PltIntegConsentRead = "PLT.INTEG-CONSENT.READ",
  PltIntegConsentWrite = "PLT.INTEG-CONSENT.WRITE",
  PltInvitationRead = "PLT.INVITATION.READ",
  PltInvitationWrite = "PLT.INVITATION.WRITE",
  PltLicencingRead = "PLT.LICENCING.READ",
  PltLicencingWrite = "PLT.LICENCING.WRITE",
  PltNotificationsWrite = "PLT.NOTIFICATIONS.WRITE",
  PltOrgUnitRead = "PLT.ORGUNIT.READ",
  PltRedirectRead = "PLT.REDIRECT.READ",
  PltRedirectWrite = "PLT.REDIRECT.WRITE",
  PltSalesRead = "PLT.SALES.READ",
  PltSalesWrite = "PLT.SALES.WRITE",
  PltComponentsRead = "PLT.COMPONENT.READ",
  PltComponentsWrite = "PLT.COMPONENT.WRITE",
  ProsSiteManagementRead = "PROS.SITEMANAGEMENT.READ",
  ProsSiteManagementWrite = "PROS.SITEMANAGEMENT.WRITE",
  ProsSiteManagementProvision = "PROS.SITEMANAGEMENT.PROVISION",
  TiCalculateWrite = "TI.BOOKING-AVAILABILITYCALC.WRITE",
  TiReportsWrite = "TI.REPORTS.WRITE",
  TiFormsAllowed = "TI.FORMDESIGN.WRITE",
  TiReportsRead = "TI.REPORTS.READ",
  TiSettingsRead = "TI.SETTINGS.READ",
  TiBhbConfigAllowed = "TI.BHB-CONFIG.ALLOWED"
}
