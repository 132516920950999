import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";

import { HttpError, HttpStatusCode, NotFoundError } from "@bps/http-client";
import { useGateways } from "@libs/api/gateways-context";
import {
  AddFieldDeviceDeploymentRingRequest,
  CommandResponse,
  CustomRJSFSchema,
  DeploymentRing,
  DeploymentRingCreateRequest,
  DeploymentRingFieldDevice,
  DeploymentRingUpdateRequest,
  DeviceCommand,
  DeviceConfigurationDiff,
  FieldDevice,
  FieldDeviceAuthAction,
  FieldDeviceAuthActionRequest,
  FieldDeviceClaim,
  FieldDeviceConfiguration,
  FieldDeviceDeploymentRingDetail,
  FieldDeviceDetails,
  FieldDeviceFilterArgs,
  FieldDeviceIdentifier,
  FieldDeviceProperty,
  FieldDeviceReportedConfig,
  Rollout,
  RolloutCreateRequest,
  SelectorArgs,
  SelectorCreateRequest,
  SelectorDto,
  SoftwarePackage,
  SoftwarePackageArgs,
  SoftwarePackageDefault,
  SoftwarePackageDefaultRequest,
  SoftwarePackageLog,
  SoftwarePackageLogArgs,
  SoftwarePackageVersion,
  SoftwarePackageVersionArgs,
  SoftwarePackageVersionConfig,
  SoftwarePublisher
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { RefDataDto } from "@libs/api/types/common-dtos";
import { guid } from "@libs/common/guid";
import { PagingResponse } from "@libs/paging/paging-response.type";
import {
  cacheDeleteSingleItemInArray,
  cacheUpsertSingleItemInArray
} from "@libs/react-query/react-query-cache.utils";
import { useRootStore } from "@stores/StoresProvider";

export const FieldOpsCacheKeys = {
  FieldDevice: "field-device",
  FieldDeviceDetails: "field-device-details",
  FieldDevices: "field-devices",
  FieldServices: "field-services",
  DeploymentRing: "deployment-ring",
  DeploymentRings: "deployment-rings",
  Rollouts: "rollouts",
  Rollout: "rollout",
  RingMembers: "ring-members",
  FieldDeviceIdentifiers: "field-device-identifiers",
  FieldDeviceIdentifierHistory: "field-device-identifier-history",
  FieldDeviceProperties: "field-device-properties",
  FieldDeviceClaims: "field-device-claims",
  SoftwarePackages: "software-packages",
  SoftwarePublishers: "software-publishers",
  SoftwarePackageVersion: "software-package-version",
  SoftwarePackageVersions: "software-package-versions",
  SoftwarePackageVersionsRollout: "software-package-versions-rollout",
  SoftwarePackageVersionConfig: "software-package-version-config",
  SoftwarePackageDefaults: "software-package-defaults",
  FieldDeviceAuthActions: "field-device-auth-actions",
  AuthActionsRefData: "auth-actions-ref-data",
  FieldDeviceDeploymentRingDetails: "field-device-deployment-ring-details",
  FieldDeviceDeploymentRing: "field-device-deployment-ring",
  FieldDeviceReportedConfig: "field-device-reported-config",
  FieldDeviceDesiredConfig: "field-device-desired-config",
  DeviceConfigurationDiff: "device-configuration-diff",
  Selectors: "selectors",
  Selector: "selector",
  SelectorDataTypeRefData: "selector-datatype-ref-data",
  DeviceLogs: "onsite-device-logs"
};

// ----- Field Devices ---------------------------------------------------------------

export const useFieldDevices = (filterArgs?: FieldDeviceFilterArgs) => {
  const { fieldOpsGateway } = useGateways();

  return useInfiniteQuery<PagingResponse<FieldDeviceDetails>, HttpError>(
    [FieldOpsCacheKeys.FieldDevices, filterArgs],
    async page => {
      return await fieldOpsGateway.getDevices(filterArgs, page.pageParam);
    },
    {
      getNextPageParam: lastPage => lastPage.next
    }
  );
};

export const useFieldDeviceQuery = (
  id: string,
  options?: Omit<UseQueryOptions<FieldDevice, Error>, "queryKey" | "queryFn">
) => {
  const { fieldOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<FieldDevice, HttpError>(
    [FieldOpsCacheKeys.FieldDevice, id],
    () => fieldOpsGateway.getDevice(id),
    {
      ...options,
      initialData: () => {
        const cache = queryClient.getQueryData<
          InfiniteData<PagingResponse<FieldDevice[]>>
        >([FieldOpsCacheKeys.FieldDevice, null]);

        const devicesFlattened = cache?.pages.reduce(
          (acc: FieldDevice[], curVal) => {
            return curVal.results !== null && curVal.results !== undefined
              ? acc.concat(...curVal.results)
              : [];
          },
          []
        );

        return devicesFlattened?.find(d => d.id === id);
      }
    }
  );
};

export const useFieldDevicesDetails = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<FieldDeviceDetails, HttpError>(
    [FieldOpsCacheKeys.FieldDeviceDetails, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getDeviceDetails(fieldDeviceId);
    },
    {
      initialData: () => {
        // Don't send request if we already have the data in previous cache of data collection, instead initialise from that data.
        const cache = queryClient.getQueryData<FieldDeviceDetails[]>([
          FieldOpsCacheKeys.FieldDevices
        ]);
        return cache?.find(d => d.id === fieldDeviceId);
      }
    }
  );
};

// ----- Field Device Identifiers --------------------------------------------

export const useFieldDeviceIdentifiers = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceIdentifier[], HttpError>(
    [FieldOpsCacheKeys.FieldDeviceIdentifiers, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getDeviceIdentifiers(fieldDeviceId);
    }
  );
};

export const useMostRecentFieldDeviceIdentifiers = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceIdentifier[], HttpError>(
    [FieldOpsCacheKeys.FieldDeviceIdentifiers, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getMostRecentDeviceIdentifierHistory(
        fieldDeviceId
      );
    }
  );
};

export const useFieldDeviceIdentifierHistory = (
  fieldDeviceId: string,
  identifierType: string
) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceIdentifier[], HttpError>(
    [
      FieldOpsCacheKeys.FieldDeviceIdentifierHistory,
      fieldDeviceId,
      identifierType
    ],
    async () => {
      return await fieldOpsGateway.getDeviceIdentifierHistory(
        fieldDeviceId,
        identifierType
      );
    }
  );
};

// ----- Field Device Properties --------------------------------------------

export const useFieldDeviceProperties = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceProperty[], HttpError>(
    [FieldOpsCacheKeys.FieldDeviceProperties, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getMostRecentDevicePropertyHistory(
        fieldDeviceId
      );
    }
  );
};

// ----- Field Device Claims --------------------------------------------

export const useFieldDeviceClaims = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceClaim[], HttpError>(
    [FieldOpsCacheKeys.FieldDeviceClaims, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getDeviceClaims(fieldDeviceId);
    }
  );
};

// ----- Rings ---------------------------------------------------------------

export const useDeploymentRings = () => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<DeploymentRing[]>(
    [FieldOpsCacheKeys.DeploymentRings],
    async () => {
      return await fieldOpsGateway.getDeploymentRings();
    }
  );
};

export const useDeploymentRing = (deploymentRingId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<DeploymentRing>(
    [FieldOpsCacheKeys.DeploymentRing, deploymentRingId],
    async () => {
      return await fieldOpsGateway.getDeploymentRing(deploymentRingId);
    }
  );
};

export const useCreateDeploymentRing = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<DeploymentRing, HttpError, DeploymentRingCreateRequest>(
    request => fieldOpsGateway.postDeploymentRing(request),
    {
      onSuccess: async deploymentRing => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [FieldOpsCacheKeys.DeploymentRings],
          item: deploymentRing
        });

        feedback.success("A deployment ring has been created.");
      },
      onError: async (error, request) => {
        if (error.httpStatusCode === HttpStatusCode.Conflict) {
          feedback.error(
            `A deployment ring already exists with code: ${request.code}`
          );
        } else {
          feedback.error(error.message);
        }
      }
    }
  );
};

export const useUpdateDeploymentRing = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<DeploymentRing, Error, DeploymentRingUpdateRequest>(
    request => fieldOpsGateway.updateDeploymentRings(request),
    {
      onSuccess: async deploymentRing => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [FieldOpsCacheKeys.DeploymentRings],
          item: deploymentRing
        });

        feedback.success("A deployment ring has been updated.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useDeleteDeploymentRing = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<void, Error, guid>(
    request => fieldOpsGateway.deleteDeploymentRings(request),
    {
      onSuccess: async (_, request) => {
        cacheDeleteSingleItemInArray({
          queryClient,
          id: request,
          queryKey: [FieldOpsCacheKeys.DeploymentRings]
        });

        feedback.success("A deployment ring has been deleted.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

// ----- Rollouts ---------------------------------------------------------------
export const useRollouts = (deploymentRingId?: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<Rollout[]>(
    [FieldOpsCacheKeys.Rollouts, deploymentRingId],
    async () => {
      return await fieldOpsGateway.getRollouts(deploymentRingId);
    }
  );
};

export const useRollout = (rolloutId?: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<Rollout>([FieldOpsCacheKeys.Rollout, rolloutId], async () => {
    return await fieldOpsGateway.getRollout(rolloutId);
  });
};

export const useCreateRollout = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<Rollout, Error, RolloutCreateRequest>(
    request => fieldOpsGateway.postRollout(request),
    {
      onSuccess: async rollout => {
        await queryClient.refetchQueries([
          FieldOpsCacheKeys.SoftwarePackageVersionsRollout,
          rollout.id
        ]);

        queryClient.setQueryData(
          [FieldOpsCacheKeys.Rollout, rollout.id],
          rollout
        );

        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [FieldOpsCacheKeys.Rollouts, rollout.deploymentRingId],
          item: rollout,
          asFirstItem: true
        });

        feedback.success("A rollout has been created.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useUpdateRollout = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<Rollout, Error, RolloutCreateRequest>(
    request => fieldOpsGateway.updateRollout(request),
    {
      onSuccess: async rollout => {
        await queryClient.refetchQueries([
          FieldOpsCacheKeys.SoftwarePackageVersionsRollout,
          rollout.id
        ]);

        queryClient.setQueryData(
          [FieldOpsCacheKeys.Rollout, rollout.id],
          rollout
        );

        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [FieldOpsCacheKeys.Rollouts, rollout.deploymentRingId],
          item: rollout
        });

        feedback.success("A rollout has been updated.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useDeleteRollout = (deploymentRingId: string) => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<void, Error, guid>(
    request => fieldOpsGateway.deleteRollout(request),
    {
      onSuccess: async (_, request) => {
        queryClient.removeQueries([FieldOpsCacheKeys.Rollout, request]);

        cacheDeleteSingleItemInArray({
          queryClient,
          id: request,
          queryKey: [FieldOpsCacheKeys.Rollouts, deploymentRingId]
        });

        feedback.success("A rollout has been deleted.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

// ----- Software Packages ------------------------------------------------------------

export const useSoftwarePackages = (args?: SoftwarePackageArgs) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePackage[], HttpError>(
    [FieldOpsCacheKeys.SoftwarePackages, args],
    async () => {
      return await fieldOpsGateway.getSoftwarePackages(args);
    }
  );
};

// ----- Software Publishers ------------------------------------------------------------

export const useSoftwarePublishers = () => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePublisher[], HttpError>(
    [FieldOpsCacheKeys.SoftwarePublishers],
    async () => {
      return await fieldOpsGateway.getSoftwarePublishers();
    }
  );
};

// ----- Software Package Versions ----------------------------------------------------

export const useSoftwarePackageVersion = (softwarePackageVersionId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePackageVersion, HttpError>(
    [FieldOpsCacheKeys.SoftwarePackageVersion, softwarePackageVersionId],
    async () => {
      return await fieldOpsGateway.getSoftwarePackageVersion(
        softwarePackageVersionId
      );
    }
  );
};

export const useSoftwarePackageVersions = (
  args?: SoftwarePackageVersionArgs
) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePackageVersion[], HttpError>(
    [FieldOpsCacheKeys.SoftwarePackageVersions, args],
    async () => {
      return await fieldOpsGateway.getSoftwarePackageVersions(args);
    }
  );
};

export const useSoftwarePackageVersionsForRollout = (rolloutId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePackageVersion[], HttpError>(
    [FieldOpsCacheKeys.SoftwarePackageVersionsRollout, rolloutId],
    async () => {
      return await fieldOpsGateway.getSoftwarePackageVersionsForRollout(
        rolloutId
      );
    }
  );
};

// ----- Software Package Version Config ------------------------------------------------------------

export const useSoftwarePackageVersionConfig = (
  softwarePackageVersionId: string
) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePackageVersionConfig[], HttpError>(
    [FieldOpsCacheKeys.SoftwarePackageVersionConfig, softwarePackageVersionId],
    async () => {
      return await fieldOpsGateway.getSoftwarePackageVersionConfig(
        softwarePackageVersionId
      );
    }
  );
};

// ----- Software Package Defaults ----------------------------------------------------

export const useSoftwarePackageDefaults = () => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SoftwarePackageDefault[], HttpError>(
    [FieldOpsCacheKeys.SoftwarePackageDefaults],
    async () => {
      return await fieldOpsGateway.getSoftwarePackageDefaults();
    }
  );
};

export const useUpdateSoftwarePackageDefault = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<SoftwarePackageDefault, Error, SoftwarePackageDefault>(
    request => fieldOpsGateway.updateSoftwarePackageDefault(request),
    {
      onSuccess: async softwarePackageDefault => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [FieldOpsCacheKeys.SoftwarePackageDefaults],
          item: softwarePackageDefault
        });

        feedback.success("Updated software package default version.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useCreateSoftwarePackageDefault = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<
    SoftwarePackageDefault,
    Error,
    SoftwarePackageDefaultRequest
  >(request => fieldOpsGateway.postSoftwarePackageDefault(request), {
    onSuccess: async softwarePackageDefault => {
      cacheUpsertSingleItemInArray({
        queryClient,
        queryKey: [FieldOpsCacheKeys.SoftwarePackageDefaults],
        item: softwarePackageDefault
      });

      feedback.success("Created software package default version.");
    },
    onError: async error => {
      feedback.error(error.message);
    }
  });
};

export const useRegenerateDefaultFieldConfig = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return async () => {
    try {
      await fieldOpsGateway.regenerateSoftwarePackageDefaultConfig();
      feedback.success("Regenerated default field configuration.");
    } catch {
      feedback.error("Error regenerating default field configuration.");
    }
  };
};

export const usePurgeFieldmanCDN = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return async () => {
    try {
      await fieldOpsGateway.purgeFieldmanCDN();
      feedback.success("Successfully sent request to purge Fieldman CDN.");
    } catch {
      feedback.error("Error requesting to purge Fieldman CDN.");
    }
  };
};

// ----- Auth Actions ------------------------------------------------------------
export const useFieldDeviceAuthActions = (
  fieldDeviceId: string,
  showExecuted: boolean
) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceAuthAction[] | undefined>(
    [FieldOpsCacheKeys.FieldDeviceAuthActions, fieldDeviceId, showExecuted],
    async () => {
      return await fieldOpsGateway.getFieldDeviceAuthActions(
        fieldDeviceId,
        showExecuted
      );
    }
  );
};

export const useCreateFieldDeviceAuthActions = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    FieldDeviceAuthAction,
    Error,
    FieldDeviceAuthActionRequest
  >(request => fieldOpsGateway.postFieldDeviceAuthActions(request), {
    onSuccess: async fieldDeviceAuthAction => {
      cacheUpsertSingleItemInArray({
        queryClient,
        queryKey: [
          FieldOpsCacheKeys.FieldDeviceAuthActions,
          fieldDeviceAuthAction.fieldDeviceId,
          false
        ],
        item: fieldDeviceAuthAction
      });

      cacheUpsertSingleItemInArray({
        queryClient,
        queryKey: [
          FieldOpsCacheKeys.FieldDeviceAuthActions,
          fieldDeviceAuthAction.fieldDeviceId,
          true
        ],
        item: fieldDeviceAuthAction
      });

      feedback.success("An auth action has been created.");
    },
    onError: async error => {
      feedback.error(error.message);
    }
  });
};

export const useUpdateFieldDeviceAuthActions = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<FieldDeviceAuthAction, Error, FieldDeviceAuthAction>(
    request => fieldOpsGateway.updateFieldDeviceAuthActions(request),
    {
      onSuccess: async fieldDeviceAuthAction => {
        if (!!fieldDeviceAuthAction.runDate) {
          cacheUpsertSingleItemInArray({
            queryClient,
            queryKey: [
              FieldOpsCacheKeys.FieldDeviceAuthActions,
              fieldDeviceAuthAction.fieldDeviceId,
              false
            ],
            item: fieldDeviceAuthAction
          });
        }

        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [
            FieldOpsCacheKeys.FieldDeviceAuthActions,
            fieldDeviceAuthAction.fieldDeviceId,
            true
          ],
          item: fieldDeviceAuthAction
        });

        feedback.success("An auth action has been updated.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useDeleteFieldDeviceAuthActions = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    request => fieldOpsGateway.deleteFieldDeviceAuthActions(request),
    {
      onSuccess: async (_, args) => {
        // Remove record from both caches (executed/ not executed) for deleted auth action.
        cacheDeleteSingleItemInArray({
          queryClient,
          id: args,
          queryKey: [
            FieldOpsCacheKeys.FieldDeviceAuthActions,
            fieldDeviceId,
            true
          ]
        });
        cacheDeleteSingleItemInArray({
          queryClient,
          id: args,
          queryKey: [
            FieldOpsCacheKeys.FieldDeviceAuthActions,
            fieldDeviceId,
            false
          ]
        });

        feedback.success("An auth action has been deleted.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useAuthActionsRefData = () => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<RefDataDto[]>(
    [FieldOpsCacheKeys.AuthActionsRefData],
    async () => {
      return await fieldOpsGateway.getAuthActionRefData();
    }
  );
};

// ----------- Field Device Reported Configuration -----------------------------

export const useFieldDeviceReportedConfig = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceReportedConfig[], HttpError>(
    [FieldOpsCacheKeys.FieldDeviceReportedConfig, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getFieldDeviceReportedConfig(fieldDeviceId);
    }
  );
};

// ----- Field Device Deployment Rings ---------------------------------------------------------------

export const useFieldDeviceDeploymentRingDetail = (
  fieldDeviceId?: string,
  deploymentRingId?: string
) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceDeploymentRingDetail[], HttpError>(
    [
      FieldOpsCacheKeys.FieldDeviceDeploymentRingDetails,
      fieldDeviceId,
      deploymentRingId
    ],
    async () => {
      return await fieldOpsGateway.getFieldDeviceDeploymentRingDetails(
        fieldDeviceId,
        deploymentRingId
      );
    }
  );
};

export const useAddDeviceDeploymentRing = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<
    DeploymentRingFieldDevice,
    Error,
    AddFieldDeviceDeploymentRingRequest
  >(request => fieldOpsGateway.postFieldDeviceDeploymentRing(request), {
    onSuccess: async () => {
      await queryClient.refetchQueries([
        FieldOpsCacheKeys.FieldDeviceDeploymentRingDetails,
        fieldDeviceId
      ]);

      feedback.success("A deployment ring has been added.");
    },
    onError: async error => {
      feedback.error(error.message);
    }
  });
};

export const useDeleteDeviceDeploymentRing = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>(
    deploymentRingFieldDeviceId =>
      fieldOpsGateway.deleteFieldDeviceDeploymentRing(
        deploymentRingFieldDeviceId
      ),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          FieldOpsCacheKeys.FieldDeviceDeploymentRingDetails,
          fieldDeviceId
        ]);

        feedback.success("A deployment ring has been removed.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useUpdateFieldDeviceDeploymentRing = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();
  return useMutation<
    DeploymentRingFieldDevice,
    Error,
    DeploymentRingFieldDevice
  >(request => fieldOpsGateway.updateFieldDeviceDeploymentRing(request), {
    onSuccess: async () => {
      await queryClient.refetchQueries([
        FieldOpsCacheKeys.FieldDeviceDeploymentRingDetails,
        fieldDeviceId
      ]);
      feedback.success("A deployment ring has been updated.");
    },
    onError: async error => {
      feedback.error(error.message);
    }
  });
};

// ----------- Field Device Configuration -----------------------------

export const useClearDeviceFieldConfigurationCache = (
  fieldDeviceId: string
) => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return async () => {
    try {
      await fieldOpsGateway.clearDeviceFieldConfigurationCache(fieldDeviceId);

      // Clear desired and dif react cache
      await queryClient.refetchQueries([
        FieldOpsCacheKeys.FieldDeviceDesiredConfig,
        fieldDeviceId
      ]);
      await queryClient.refetchQueries([
        FieldOpsCacheKeys.DeviceConfigurationDiff,
        fieldDeviceId
      ]);

      feedback.success(
        "Field configuration cache cleared for this field device."
      );
    } catch {
      feedback.error("Error requesting to purge field configuration cache.");
    }
  };
};

// ----------- Field Device Desired Configuration -----------------------------

export const useFieldDeviceConfiguration = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<FieldDeviceConfiguration, HttpError>(
    [FieldOpsCacheKeys.FieldDeviceDesiredConfig, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getFieldDeviceDesiredConfig(fieldDeviceId);
    }
  );
};

// ----------- Software Package Command Json -----------------------------

export const useSoftwarePackageCommandJson = (
  softwarePackageVersionId?: string
) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<CustomRJSFSchema[], HttpError>(
    [FieldOpsCacheKeys.FieldDeviceDesiredConfig, softwarePackageVersionId],
    async () => {
      try {
        return await fieldOpsGateway.getSoftwarePackgeCommandJson(
          softwarePackageVersionId
        );
      } catch (e) {
        if (e instanceof NotFoundError) {
          return [];
        }
        return e;
      }
    }
  );
};

// ----------- Device Configuration Diff -----------------------------

export const useDeviceConfigurationDiff = (fieldDeviceId: string) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<DeviceConfigurationDiff, HttpError>(
    [FieldOpsCacheKeys.DeviceConfigurationDiff, fieldDeviceId],
    async () => {
      return await fieldOpsGateway.getDeviceConfigurationDiff(fieldDeviceId);
    }
  );
};

// ----------- Selectors -----------------------------

export const useSelectors = (args?: SelectorArgs) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SelectorDto[], HttpError>(
    [FieldOpsCacheKeys.Selectors, args],
    async () => {
      return await fieldOpsGateway.getSelectors(args);
    }
  );
};

export const useCreateSelector = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<SelectorDto, HttpError, SelectorCreateRequest>(
    request => fieldOpsGateway.postSelector(request),
    {
      onSuccess: async () => {
        // Doing full refetch here because we would have to pass filter args here + upsert for every possible arg.
        await queryClient.refetchQueries([FieldOpsCacheKeys.Selectors]);

        feedback.success("A selector has been created.");
      },
      onError: async error => {
        feedback.error(error.detail as string);
      }
    }
  );
};

export const useUpdateSelector = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  // Returns a Selector and takes a selector
  return useMutation<SelectorDto, HttpError, SelectorDto>(
    request => fieldOpsGateway.putSelector(request),
    {
      onSuccess: async () => {
        // Doing full refetch here because we would have to pass filter args here + upsert for every possible arg.
        await queryClient.refetchQueries([FieldOpsCacheKeys.Selectors]);

        feedback.success("Selector has been updated.");
      },
      onError: async error => {
        feedback.error(error.detail as string);
      }
    }
  );
};

export const useSelector = (selectorId: guid) => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<SelectorDto, HttpError>(
    [FieldOpsCacheKeys.Selector, selectorId],
    async () => {
      return await fieldOpsGateway.getSelector(selectorId);
    }
  );
};

export const useUpdateSelectorDeviceCount = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<SelectorDto, HttpError, SelectorDto>(
    request => fieldOpsGateway.updateSelectorDeviceCount(request),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([FieldOpsCacheKeys.Selectors]);

        feedback.success("Device count has been updated.");
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useSelectorDataTypeRefData = () => {
  const { fieldOpsGateway } = useGateways();

  return useQuery<RefDataDto[]>(
    [FieldOpsCacheKeys.SelectorDataTypeRefData],
    async () => {
      return await fieldOpsGateway.getSelectorDataTypeRefData();
    }
  );
};

// ----------- Field Gateway --------------------------------

export const useDispatchDeviceCommand = () => {
  const { fieldOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<CommandResponse, HttpError, DeviceCommand>(
    command => fieldOpsGateway.dispatchDeviceCommand(command),
    {
      onSuccess: async () => {
        feedback.success("Successfully sent command to device.");
      },
      onError: async error => {
        feedback.success("Error sending command to device.");
        throw error;
      }
    }
  );
};

// ----------- Device Logs -----------------------------

export const useDeviceLogs = (args: SoftwarePackageLogArgs) => {
  const { fieldOpsGateway } = useGateways();

  return useInfiniteQuery<PagingResponse<SoftwarePackageLog>, HttpError>(
    [FieldOpsCacheKeys.DeviceLogs, args],
    async page => {
      return await fieldOpsGateway.getDeviceLogs(args, page.pageParam);
    },
    {
      getNextPageParam: lastPage => lastPage.next
    }
  );
};
